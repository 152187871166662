import { Parallax } from "react-parallax";

export default function Testimonals() {
  return (
    <Parallax className="parallax-container" bgImage="images/bg-01.jpg">
      <div className="parallax-content section-lg">
        <div className="container text-center">
          <h2 className="wow fadeInSmall" data-wow-delay=".1s">
            Хэрэглэгчийн сэтгэгдэл
          </h2>
          <div className="divider wow fadeInSmall" data-wow-delay=".2s" />
          <div
            className="owl-carousel owl-carousel-blockquote"
            data-items={1}
            data-dots="true"
            data-nav="true"
            data-stage-padding={15}
            data-loop="true"
            data-margin={30}
            data-mouse-drag="false"
            data-autoplay="true"
          >
            <blockquote className="blockquote-sinple">
              <span className="blockquote-sinple-mark">
                <svg
                  viewBox="0 0 63 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M56.4453 3.61328C52.2786 5.37109 48.6979 8.13802 45.7031 11.9141C42.7083 15.625 41.2109 19.401 41.2109 23.2422C41.2109 24.8698 41.4062 26.2695 41.7969 27.4414C44.0755 25.6836 46.5495 24.8047 49.2188 24.8047C52.8646 24.8047 55.8919 25.9766 58.3008 28.3203C60.7747 30.599 62.0117 33.6914 62.0117 37.5977C62.0117 41.2435 60.7747 44.3034 58.3008 46.7773C55.8268 49.1862 52.7995 50.3906 49.2188 50.3906C44.0755 50.3906 40.1367 48.2747 37.4023 44.043C35.1237 40.5273 33.9844 36.1328 33.9844 30.8594C33.9844 24.1536 35.6771 18.1641 39.0625 12.8906C42.513 7.55208 47.6888 3.25521 54.5898 0L56.4453 3.61328ZM22.4609 3.61328C18.2943 5.37109 14.7135 8.13802 11.7188 11.9141C8.72396 15.625 7.22656 19.401 7.22656 23.2422C7.22656 24.8698 7.42188 26.2695 7.8125 27.4414C10.026 25.6836 12.5 24.8047 15.2344 24.8047C18.8151 24.8047 21.8424 25.9766 24.3164 28.3203C26.7904 30.599 28.0273 33.6914 28.0273 37.5977C28.0273 40.0065 27.4414 42.1875 26.2695 44.1406C25.0977 46.0938 23.5352 47.6237 21.582 48.7305C19.6289 49.8372 17.513 50.3906 15.2344 50.3906C10.0911 50.3906 6.15234 48.2747 3.41797 44.043C1.13932 40.5273 0 36.1328 0 30.8594C0 24.1536 1.69271 18.1641 5.07812 12.8906C8.52865 7.55208 13.7044 3.25521 20.6055 0L22.4609 3.61328Z" />
                </svg>
              </span>
              <p>
                <q className="heading-5">
                  1. Чанартай
                  <br />
                  2. Баталгаатай
                  <br />
                  3. Хэлэх үг харуулах амжилттай.
                </q>
              </p>
              <cite className="cite">Cold Song</cite>
              <span className="blockquote-sinple-position">Үйлчлүүлэгч</span>
            </blockquote>
          </div>
        </div>
      </div>
    </Parallax>
  );
}
