import "./App.css";
import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/about_us";
import Porfolio from "./pages/portfolio";
import ServicePage from "./pages/service";
import ContactUs from "./pages/contact_us";
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/portfolio" element={<Porfolio />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
      <div className="snackbars" id="form-output-global" />
    </>
  );
}

export default App;
