import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <React.Fragment>
      <section className="section pre-footer-classic section-lg context-dark">
        <div className="container">
          <div className="row row-30 justify-content-md-between">
            <div className="col-xs-6 col-md-3 col-xl-2">
              <Link to={"/"}>
                <img
                  src="images/logo-inverse-185x34.png"
                  alt={"true"}
                  width={185}
                  height={34}
                  loading="lazy"
                />
              </Link>
              <p>
                Ажиллах цаг: 09:00-19:00
                <br />
                Даваа амралт
              </p>
              <ul className="list-inline">
                <li>
                  <Link
                    className="icon icon-primary icon-xs icon-circle fa-facebook-f"
                    to={"https://www.facebook.com/ArisniiDasgaljuulagch"}
                    target="_blank"
                  ></Link>
                </li>
                <li>
                  <Link
                    className="icon icon-primary icon-xs icon-circle fa-instagram"
                    to={"https://www.instagram.com/dasgalzhuulagch/"}
                    target="_blank"
                  ></Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-3 col-md-2">
              <h6>Холбоос</h6>
              <ul className="list list-marked">
                <li className="active">
                  <Link to={"/"}>Нүүр</Link>
                </li>
                <li>
                  <Link to={"/about"}>Бидний тухай</Link>
                </li>
                <li>
                  <Link to={"/portfolio"}>Галлерай</Link>
                </li>
                <li>
                  <Link to={"/service"}>Үйлчилгээ</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-8 col-md-4 col-lg-3">
              <h6>Холбоо барих</h6>
              <ul className="list-contact">
                <li>
                  <div className="list-contact-item">
                    <div className="list-contact-item-left">
                      <span className="icon icon-lg icon-primary linearicons-map2" />
                    </div>
                    <div className="list-contact-item-body">
                      <p>Хаяг</p>
                      <Link
                        to={"https://goo.gl/maps/UPvFaLuHxASaTQXr7"}
                        target="_blank"
                      >
                        Улаанбаатар, Баянзүрх дүүрэг, 14-р хороо, 13-р хороолол,
                        Нийслэл төв, 6 давхар 638 тоот
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list-contact-item">
                    <div className="list-contact-item-left">
                      <span className="icon icon-lg icon-primary linearicons-telephone" />
                    </div>
                    <div className="list-contact-item-body">
                      <p>Залгах</p>
                      <a href="tel:77118131">(+976) 77118131</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list-contact-item">
                    <div className="list-contact-item-left">
                      <span className="icon icon-lg icon-primary linearicons-clock3" />
                    </div>
                    <div className="list-contact-item-body">
                      <p>И-Мэйл: </p>
                      <a href="mailto:#">aris_archilgaa@gmail.com</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <footer className="section footer-classic context-dark">
        <div className="container">
          <p className="rights">
            Арьсны дасгалжуулагч &nbsp;
            <span>©&nbsp;{new Date().getFullYear()}&nbsp;</span>
            Бүх эрх хуулиар хамгаалагдсан
            <br />
            <span>
              Вэбсайт дизайн:{" "}
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
                to={"https://fununity.mn/"}
                target="_blank"
              >
                Fun Unity
              </Link>
            </span>
          </p>
        </div>
      </footer>
      <div className="snackbars" id="form-output-global" />
    </React.Fragment>
  );
}
