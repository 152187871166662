import React from "react";
import Testimonals from "../../components/Testimonals";
import { Parallax } from "react-parallax";
import MiniGallery from "../../components/MiniGaleria";
import { Link } from "react-router-dom";
import StatusSection from "../../components/StatusSection";
import MembersSection from "../../components/MembersSection";

export default function AboutUs() {
  return (
    <React.Fragment>
      <section className="section bg-default breadcrumbs-custom">
        <span className="breadcrumbs-custom-back-title">Бидний тухай</span>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-11 position-relative">
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to={"/"}>Нүүр хуудас</Link>
                </li>
                <li className="active">Бидний тухай</li>
              </ul>
              <h1 className="breadcrumbs-custom-title">Бидний тухай</h1>
              <div className="divider divider-xl" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row row-30 align-items-md-center justify-content-center">
            <div className="col-sm-10 col-md-8 col-lg-7">
              <img
                src="images/about-01-630x420.jpg"
                alt="skintrainer"
                width={630}
                height={420}
                loading="lazy"
              />
            </div>
            <div className="col-sm-10 col-md-8 col-lg-5 text-center">
              <p>
                Манай эмнэлэг 2019 оноос хойш үйл ажиллагаагаа явуулсаар байна.
                Бид энэ хугацаанд бүх төрлийн батгашилттай арьсыг эмчилж маш
                олон хүмүүсийг арьсны асуудлыг шийдсээр ирсэн. Бид 2020 оноос
                хойш үйл ажиллагаа болон эмчилгээг улам сайжруулах зорилготой
                ажиллаж байна. Батганы асуудалтай хэн бүхэнд манай эмнэлэг
                нээлттэй. Таны тав тухтай орчин, найрсаг хамт олон хүлээж байна.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Parallax
        className="parallax-container parallax-primary-custom"
        bgImage="images/bg-parallax-01.jpg"
      >
        <div className="parallax-content section-xl">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="wow fadeInSmall" data-wow-delay=".1s">
                  <span>Үйлчилгээ</span>
                </h2>
                <p className="wow fadeInSmall" data-wow-delay=".2s">
                  Бүхийн төрлийн арьсны асуудалтай бол та манай үйлчилгээг
                  сонирхоод үзээрэй
                </p>
                <a
                  className="button button-white-full wow fadeInSmall"
                  data-wow-delay=".3s"
                  href="/service"
                >
                  Дэлгэрэнгүй
                </a>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
      <StatusSection />
      <MembersSection />
      <Testimonals />
      <MiniGallery />
    </React.Fragment>
  );
}
