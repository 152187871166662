/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

export default function ContactUs() {
  return (
    <React.Fragment>
      <section className="section bg-default breadcrumbs-custom breadcrumbs-custom-bottom-40">
        <span className="breadcrumbs-custom-back-title">Холбоо барих</span>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-11 position-relative">
              <ul className="breadcrumbs-custom-path">
                <li>
                  <a href="/">Нүүр</a>
                </li>
                <li className="active">Холбоо барих</li>
              </ul>
              <h1 className="breadcrumbs-custom-title">Холбоо барих</h1>
              <div className="divider divider-xl" />
              <p style={{ fontSize: "1.3rem" }}>
                Танд манай үйлчилгээний талаар асуух зүйл байна уу?
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-lg bg-default">
        <div className="container">
          <div className="row justify-content-center align-items-md-center row-30">
            <div className="col-md-5 col-xl-4">
              <div className="block-xs">
                <h4>Холбогдох мэдээлэл</h4>
                <ul className="list-contact list-contact-sm list-contact-classic">
                  <li>
                    <div className="list-contact-item align-items-center">
                      <div className="list-contact-item-left">
                        <span className="icon icon-lg icon-primary linearicons-map2" />
                      </div>
                      <div className="list-contact-item-body">
                        <a
                          href="https://goo.gl/maps/UPvFaLuHxASaTQXr7"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Улаанбаатар, Баянзүрх дүүрэг, 14-р хороо, 13-р
                          хороолол, Нийслэл төв, 6 давхар 638 тоот
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list-contact-item">
                      <div className="list-contact-item-left">
                        <span className="icon icon-lg icon-primary linearicons-telephone" />
                      </div>
                      <div className="list-contact-item-body">
                        <a href="tel:77118131">(+976) 7711-8131</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list-contact-item">
                      <div className="list-contact-item-left">
                        <span className="icon icon-lg icon-primary linearicons-envelope" />
                      </div>
                      <div className="list-contact-item-body">
                        <a href="mailto:aris_archilgaa@gmail.com">
                          aris_archilgaa@gmail.com
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <h4>Цахим хаягууд</h4>
                <ul className="list-inline">
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-facebook-f"
                      href="https://www.facebook.com/ArisniiDasgaljuulagch"
                      target="_blank"
                      rel="noreferrer"
                    />
                  </li>
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-instagram"
                      href="https://www.instagram.com/dasgalzhuulagch/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7 col-xl-6">
              <form
                className="rd-mailform text-start"
                data-form-output="form-output-global"
                data-form-type="contact"
                method="post"
                action="bat/rd-mailform.php"
              >
                <div className="row row-20">
                  <div className="col-lg-6">
                    <div className="form-wrap form-validation-top">
                      <label
                        className="form-label form-label-outside"
                        htmlFor="contact-name"
                      >
                        Таны нэр
                      </label>
                      <input
                        className="form-input"
                        id="contact-name"
                        type="text"
                        name="name"
                        data-constraints="@Required"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-wrap form-validation-top">
                      <label
                        className="form-label form-label-outside"
                        htmlFor="contact-email"
                      >
                        Цахим шуудан
                      </label>
                      <input
                        className="form-input"
                        id="contact-email"
                        type="email"
                        name="email"
                        data-constraints="@Required @Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-wrap form-validation-top">
                      <label
                        className="form-label form-label-outside"
                        htmlFor="contact-message"
                      >
                        Таны хүсэлт
                      </label>
                      <textarea
                        className="form-input"
                        id="contact-message"
                        name="message"
                        data-constraints="@Required"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-button group-sm">
                  <button className="button button-primary" type="submit">
                    Илгээх
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <iframe
          title="google map"
          class="google-map-container"
          src="https://maps.google.com/maps?hl=mn&q=Amar Agent-Sunday Plaza&t=&z=14&ie=UTF8&iwloc=B&output=embed"
          style={{
            height: "40vh",
          }}
        ></iframe>
      </section>
    </React.Fragment>
  );
}
