import React from "react";
import { Link, useMatch } from "react-router-dom";

export default function Header() {
  const match = useMatch("/");

  const renderLinks = routes.map((e) => <MyNavlink {...e} key={e.path} />);

  if (!match) {
    return (
      <header className="section page-header">
        <div className="rd-navbar-wrap">
          <nav
            className="rd-navbar rd-navbar-classic"
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-static"
            data-lg-device-layout="rd-navbar-fixed"
            data-xl-layout="rd-navbar-static"
            data-xl-device-layout="rd-navbar-static"
            data-auto-height="true"
            data-lg-stick-up-offset="46px"
            data-xl-stick-up-offset="46px"
            data-xxl-stick-up-offset="46px"
            data-lg-stick-up="true"
            data-xl-stick-up="true"
            data-xxl-stick-up="true"
          >
            <div className="rd-navbar-main-outer">
              <div className="rd-navbar-main">
                <div className="rd-navbar-panel">
                  <button
                    className="rd-navbar-toggle"
                    data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                  >
                    <span />
                  </button>
                  <div className="rd-navbar-brand">
                    <Link to={"/"}>
                      <img
                        className="brand-logo-dark"
                        src="images/logo-default-166x30.png"
                        alt="logo"
                        width={166}
                        height={30}
                        loading="lazy"
                      />
                      <img
                        className="brand-logo-light"
                        src="images/logo-inverse-166x30.png"
                        alt="logo"
                        width={166}
                        height={30}
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </div>
                <div className="rd-navbar-main-element">
                  <div className="rd-navbar-nav-wrap">
                    <ul className="rd-navbar-nav">{renderLinks}</ul>
                  </div>
                  <div
                    className="rd-navbar-collapse-toggle rd-navbar-fixed-element-1"
                    data-rd-navbar-toggle=".rd-navbar-collapse"
                  >
                    <span />
                  </div>
                  <div className="rd-navbar-collapse">
                    <div className="rd-navbar-aside">
                      <ul className="list-inline list-inline-md">
                        <li>
                          <div className="unit unit-spacing-lg align-items-center">
                            <div className="unit-left">
                              <span className="icon icon-xs icon-primary text-middle fa-phone" />
                            </div>
                            <div className="unit-body">
                              <a href="tel:77118131">(+976) 77118131</a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }

  return (
    <React.Fragment>
      <header className="section page-header">
        {/* RD Navbar*/}
        <div className="rd-navbar-wrap">
          <nav
            className="rd-navbar rd-navbar-transparent rd-navbar-transparent-white"
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-static"
            data-lg-device-layout="rd-navbar-fixed"
            data-xl-layout="rd-navbar-static"
            data-xl-device-layout="rd-navbar-static"
            data-auto-height="false"
            data-lg-stick-up-offset="46px"
            data-xl-stick-up-offset="46px"
            data-xxl-stick-up-offset="46px"
            data-lg-stick-up="true"
            data-xl-stick-up="true"
            data-xxl-stick-up="true"
          >
            <div className="rd-navbar-main-outer">
              <div className="rd-navbar-main">
                {/* RD Navbar Panel*/}
                <div className="rd-navbar-panel">
                  {/* RD Navbar Toggle*/}
                  <button
                    className="rd-navbar-toggle"
                    data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                  >
                    <span />
                  </button>
                  {/* RD Navbar Brand*/}
                  <div className="rd-navbar-brand">
                    <a className="brand" href="index_1.html">
                      <img
                        className="brand-logo-dark"
                        src="images/logo-default-166x30.png"
                        alt={"true"}
                        width={166}
                        height={30}
                        loading="lazy"
                      />
                      <img
                        className="brand-logo-light"
                        src="images/logo-inverse-166x30.png"
                        alt={"true"}
                        width={166}
                        height={30}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
                <div className="rd-navbar-main-element">
                  <div className="rd-navbar-nav-wrap">
                    {/* RD Navbar Nav*/}
                    <ul className="rd-navbar-nav">{renderLinks}</ul>
                  </div>
                  <div
                    className="rd-navbar-collapse-toggle rd-navbar-fixed-element-1"
                    data-rd-navbar-toggle=".rd-navbar-collapse"
                  >
                    <span />
                  </div>
                  <div className="rd-navbar-collapse">
                    <div className="rd-navbar-aside">
                      <ul className="list-inline list-inline-md">
                        <li>
                          <div className="unit unit-spacing-lg align-items-center">
                            <div className="unit-left">
                              <span className="icon icon-xs icon-primary text-middle fa-phone" />{" "}
                              Утас:
                            </div>
                            <div className="unit-body">
                              <a href="tel:77118131">(+976) 77118131</a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
}

function MyNavlink({ title, path }) {
  const match = useMatch(path);
  return (
    <li className={`rd-nav-item ${match ? "active" : ""}`}>
      <a className="rd-nav-link" href={path}>
        {title}
      </a>
    </li>
  );
}

const routes = [
  {
    title: "Нүүр",
    path: "/",
  },
  {
    title: "Бидний тухай",
    path: "/about",
  },
  {
    title: "Үйлчилгээ",
    path: "/service",
  },
  {
    path: "/portfolio",
    title: "Галлерай",
  },
  { title: "Холбоо барих", path: "/contact-us" },
];
