export default function Porfolio() {
  return (
    <section className="section bg-default breadcrumbs-custom">
      <span className="breadcrumbs-custom-back-title">Галерей</span>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-11 position-relative">
            <ul className="breadcrumbs-custom-path">
              <li>
                <a href="index_1.html">Нүүр</a>
              </li>
              <li className="active">Галерей</li>
            </ul>
            <h1 className="breadcrumbs-custom-title">Галерей</h1>
            <div className="divider divider-xl" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <h2 className="heading-3">Үйлчилгээний зураг</h2>
          </div>
        </div>
        <div
          className="row row-20 justify-content-center justify-content-sm-start"
          data-lightgallery="group"
        >
          <div
            className="col-xs-10 col-sm-6 col-md-4 wow fadeInSmall"
            data-wow-delay=".1s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-01-original-1200x1200.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-01-380x380.jpg"
                  alt="6"
                  width={380}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
          <div
            className="col-xs-10 col-sm-6 col-md-4 wow fadeInSmall"
            data-wow-delay=".2s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-02-original-1200x1200.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-02-380x380.jpg"
                  alt="6"
                  width={380}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
          <div
            className="col-xs-10 col-sm-6 col-md-4 wow fadeInSmall"
            data-wow-delay=".3s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-03-original-1200x1200.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-03-380x380.jpg"
                  alt="6"
                  th={380}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
          <div
            className="col-xs-10 col-sm-6 col-md-4 wow fadeInSmall"
            data-wow-delay=".4s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-04-original-1200x1200.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-04-380x380.jpg"
                  alt="5"
                  width={380}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
          <div
            className="col-xs-10 col-sm-12 col-md-8 wow fadeInSmall"
            data-wow-delay=".5s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-05-original-1200x585.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-05-780x380.jpg"
                  alt="3"
                  width={780}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
          <div
            className="col-xs-10 col-sm-12 col-md-8 wow fadeInSmall"
            data-wow-delay=".6s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-06-original-1200x585.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-06-780x380.jpg"
                  alt="2"
                  width={780}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
          <div
            className="col-xs-10 col-sm-6 col-md-4 wow fadeInSmall"
            data-wow-delay=".7s"
          >
            <a
              className="thumbnail-classic"
              href="images/gallery-07-original-1200x1200.jpg"
              data-lightgallery="item"
            >
              <div className="thumbnail-classic-img">
                <img
                  src="images/gallery-07-380x380.jpg"
                  alt="1"
                  width={380}
                  height={380}
                  loading="lazy"
                />
              </div>
              <div className="thumbnail-classic-caption" />
              <div className="thumbnail-classic-icon linearicons-zoom-in" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
