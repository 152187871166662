import React from "react";
import MiniGaleria from "../../components/MiniGaleria";
import { Parallax } from "react-parallax";

export default function ServicePage() {
  return (
    <React.Fragment>
      <section className="section bg-default breadcrumbs-custom">
        <span className="breadcrumbs-custom-back-title">Үйлчилгээ</span>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-11 position-relative">
              <ul className="breadcrumbs-custom-path">
                <li>
                  <a href="/">Нүүр</a>
                </li>
                <li className="active">Үйлчилгээ</li>
              </ul>
              <h1 className="breadcrumbs-custom-title">Үйлчилгээ</h1>
              <div className="divider divider-xl" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xs-10">
              <h2 className="heading-3">
                Нотолгоонд тулгуурласан, мэргэжлийн, орчин үеийн хамт олон танд
                үйлчилж байна.
              </h2>
            </div>
          </div>
          <div className="row row-30">
            <ServiceItem name="Мэнгэ авах" image="/images/mole-removal.jpg" />
            <ServiceItem name="Сэвх задлах" image="/images/freckles.jpg" />
            <ServiceItem
              name="Дааврын батга эмчилгээ"
              image="/images/acne.png"
            />
            <ServiceItem name="Сорвины эмчилгээ" image="/images/scar.png" />
            <ServiceItem name="Үү ургацаг авах" image="/images/oou.jpg" />
            <ServiceItem name="Ботокс" image="/images/botox.jpg" />
          </div>
        </div>
      </section>
      <Parallax
        className="parallax-container parallax-gray-overlay"
        bgImage="images/bg-parallax-02.jpg"
      >
        <div className="parallax-content section-xxl context-dark text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-xl-8">
                <h2 className="wow fadeInSmall" data-wow-delay=".1s">
                  <span>Мэдээлэл</span>
                </h2>
                <p className="wow fadeInSmall" data-wow-delay=".2s">
                  Үйлчилгээний дэлгэрэнгүй мэдээлэл авахыг хүсэж байвал бид
                  нартай холбоо барин уу
                </p>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
      <MiniGaleria />
    </React.Fragment>
  );
}

function ServiceItem({
  image = "/images/services-03-420x526.jpg",
  name = "Нэр байхгүй байна",
  description = "",
}) {
  return (
    <div className="col-md-6 col-lg-4 wow fadeInSmall" data-wow-delay=".1s">
      <div className="box-services-modern">
        <div
          className="box-services-modern-image"
          style={{
            aspectRatio: "3/4",
            overflow: "hidden",
          }}
        >
          <img
            src={image}
            alt={image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="box-services-modern-body">
          <h4 className="box-services-modern-title">{name}</h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
