function MembersSection() {
  return (
    <section className="section section-lg bg-gray-200 text-center">
      <div className="container">
        <h2 className="wow fadeInSmall" data-wow-delay=".1s">
          <span>Манай баг хамт олон</span>
        </h2>
        <div className="divider wow fadeInSmall" data-wow-delay=".2s" />
        <div className="row row-30">
          <div
            className="col-sm-6 col-lg-3 wow fadeInSmall"
            data-wow-delay=".1s"
          >
            <div className="box-expert">
              <div className="box-expert-image">
                <img
                  src="/images/profile.png"
                  alt="expert "
                  width={200}
                  height={200}
                  loading="lazy"
                />
              </div>
              <div className="box-expert-body">
                <h6>Ариунболд</h6>
                <p>Эмч</p>
                <ul className="list-inline">
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-facebook-f"
                      href="#"
                    />
                  </li>
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-instagram"
                      href="#"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6 col-lg-3 wow fadeInSmall"
            data-wow-delay=".2s"
          >
            <div className="box-expert">
              <div className="box-expert-image">
                <img src="/images/profile.png" alt width={200} height={200} />
              </div>
              <div className="box-expert-body">
                <h6>Саранчимэг</h6>
                <p>Зөвлөх эмч</p>
                <ul className="list-inline">
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-facebook-f"
                      href="#"
                    />
                  </li>
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-instagram"
                      href="#"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6 col-lg-3 wow fadeInSmall"
            data-wow-delay=".4s"
          >
            <div className="box-expert">
              <div className="box-expert-image">
                <img src="/images/profile.png" alt width={200} height={200} />
              </div>
              <div className="box-expert-body">
                <h6>Ариунсарнай</h6>
                <p>Эмч</p>
                <ul className="list-inline">
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-facebook-f"
                      href="#"
                    />
                  </li>
                  <li>
                    <a
                      className="icon icon-secondary-1 icon-xs icon-circle fa-instagram"
                      href="#"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MembersSection;
