function StatusSection() {
  return (
    <section className="section section-lg bg-default">
      <div className="container">
        <div className="row row-30">
          <div
            className="col-xs-6 col-lg-4 wow fadeInSmall"
            data-wow-delay=".1s"
          >
            <div className="features-box">
              <span className="icon icon-xxl icon-primary linearicons-woman" />
              <h4 className="features-box-title">Үйлчлүүлэгч</h4>
              <p>Сэтгэл ханамж дүүрэн үйлчлүүлэгч</p>
            </div>
          </div>
          <div
            className="col-xs-6 col-lg-4 wow fadeInSmall"
            data-wow-delay=".2s"
          >
            <div className="features-box">
              <span className="icon icon-xxl icon-primary linearicons-diamond3" />
              <h4 className="features-box-title">Үнэт зүйл</h4>
              <p>Эрүүл гэрэлтсэн арьс бидний үнэт зүйл</p>
            </div>
          </div>
          <div
            className="col-xs-6 col-lg-4 wow fadeInSmall"
            data-wow-delay=".3s"
          >
            <div className="features-box">
              <span className="icon icon-xxl icon-primary linearicons-first-aid" />
              <h4 className="features-box-title">Үйлчилгээ</h4>
              <p>Манай үйлчилгээ нь чанартай бас баталгаатай</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatusSection;
