export default function MiniGallery() {
  return (
    <section className="section section-lg section-bottom-0 bg-default text-center">
      <div className="container">
        <a className="wow fadeInSmall" data-wow-delay=".2s" href="/portfolio">
          <h4 className="font-secondary-1 wow fadeInSmall">
            Бидний уран бүтээл
          </h4>
        </a>
      </div>
      <div
        className="owl-carousel owl-carousel-instagram"
        data-lightgallery="group"
        data-items={1}
        data-xs-items={2}
        data-sm-items={3}
        data-md-items={3}
        data-lg-items={4}
        data-xl-items={5}
        data-autoplay="true"
        data-dots="true"
        data-nav="false"
        data-stage-padding={0}
        data-loop="true"
        data-margin={0}
        data-mouse-drag="true"
      >
        <div className="thumb-box">
          <div className="thumb-box-image">
            <a
              href="images/thumb-original-06-1200x800.jpg"
              data-lightgallery="item"
            >
              <img
                src="images/thumb-06-576x576.jpg"
                alt="1"
                width={576}
                height={576}
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="thumb-box">
          <div className="thumb-box-image">
            <a
              href="images/thumb-original-07-1200x701.jpg"
              data-lightgallery="item"
            >
              <img
                src="images/thumb-07-576x576.jpg"
                alt="2"
                width={576}
                height={576}
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="thumb-box">
          <div className="thumb-box-image">
            <a
              href="images/thumb-original-08-1200x739.jpg"
              data-lightgallery="item"
            >
              <img
                src="images/thumb-08-576x576.jpg"
                alt="3"
                width={576}
                height={576}
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="thumb-box">
          <div className="thumb-box-image">
            <a
              href="images/thumb-original-09-1200x808.jpg"
              data-lightgallery="item"
            >
              <img
                src="images/thumb-09-576x576.jpg"
                alt="4"
                width={576}
                height={576}
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="thumb-box">
          <div className="thumb-box-image">
            <a
              href="images/thumb-original-10-1200x965.jpg"
              data-lightgallery="item"
            >
              <img
                src="images/thumb-10-576x576.jpg"
                alt="5"
                width={576}
                height={576}
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
