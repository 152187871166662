import React from "react";
import StatusSection from "../../components/StatusSection";
import MembersSection from "../../components/MembersSection";

export default function Home() {
  return (
    <React.Fragment>
      <section
        className="section swiper-container swiper-slider swiper-slider-3 context-dark"
        data-swiper='{"loop":false,"autoplay":{"delay":4000},"effect":"fade","simulateTouch":false}'
      >
        <div className="swiper-wrapper">
          <div
            className="swiper-slide"
            data-slide-bg="/images/slide-05.jpg"
            // style={{ backgroundPosition: "center" }}
          >
            <div className="swiper-slide-caption section-xl">
              <div className="container">
                <div className="row">
                  <div className="col-xs-9 col-sm-7 col-md-6">
                    <div className="block-lg">
                      <div
                        className="heading-1"
                        data-caption-animate="fadeInUp"
                        data-caption-delay={100}
                      >
                        Арьс дасгалжуулагч
                      </div>
                      <p
                        style={{
                          fontSize: "1.2rem",
                        }}
                        data-caption-animate="fadeInUp"
                        data-caption-delay={350}
                      >
                        Эрүүл гэрэлтсэн арьс бидний нэрийн хуудас юм
                      </p>
                      <a
                        className="button button-primary"
                        href="tel:77118131"
                        data-caption-animate="fadeInUp"
                        data-caption-delay={450}
                      >
                        Цаг авах
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StatusSection />
      <section
        className="parallax-container"
        data-parallax-img="images/bg-01.jpg"
      >
        <div className="parallax-content section-lg">
          <div className="container">
            <div className="row justify-content-xs-center">
              <div className="col-xs-10 col-sm-10 col-md-8 col-lg-6">
                <h2 className="wow fadeInSmall" data-wow-delay=".1s">
                  Бидний тухай
                </h2>
                <div className="divider wow fadeInSmall" data-wow-delay=".2s" />
                <p
                  className="wow fadeInSmall"
                  data-wow-delay=".3s"
                  style={{ fontSize: "1.2rem" }}
                >
                  Манай эмнэлэг 2019 оноос хойш үйл ажиллагаагаа явуулсаар
                  байна. Бид энэ хугацаанд бүх төрлийн батгашилттай арьсыг
                  эмчилж маш олон хүмүүсийг арьсны асуудлыг шийдсээр ирсэн. Бид
                  2020 оноос хойш үйл ажиллагаа болон эмчилгээг улам сайжруулах
                  зорилготой ажиллаж байна. Батганы асуудалтай хэн бүхэнд манай
                  эмнэлэг нээлттэй. Таны тав тухтай орчин, найрсаг хамт олон
                  хүлээж байна.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MembersSection />
      <section className="section section-lg bg-default section-decor">
        <div className="container">
          <div className="row row-30 flex-lg-row-reverse">
            <div className="col-lg-7 wow fadeInSmall" data-wow-delay=".3s">
              <div className="video" data-lightgallery="group">
                <img
                  src="images/video-preview-680x398.jpg"
                  alt={"true"}
                  width={680}
                  height={398}
                  loading="lazy"
                />
                <a
                  className="video-link"
                  data-lightgallery="item"
                  href="watch.html"
                >
                  <img
                    src="images/video-play-outline-90x90.png"
                    alt={"true"}
                    width={90}
                    height={90}
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="block-sm-min position-relative"
                style={{
                  fontSize: "1.2rem",
                }}
              >
                <h2 className="wow fadeInSmall" data-wow-delay=".1s">
                  Бидний онцлог
                </h2>
                <p>
                  Батгашилт өвчин нь бүх насны хүмүүст тохиолдож болно. Зарим
                  хүмүүст илүү ихээр үрэвсэж хүндэрдэг.
                </p>
                <ul>
                  <li>- Шилжилтийн насны батгажилт</li>
                  <li>- Дааврын батгашилт</li>
                  <li>- Амьдралын буруу хэв маягаас үүссэн батгашилт</li>
                </ul>
                <p>
                  Эдгээрээс болж, арьсанд сорвижилт үүсэн сэтгэл санааны
                  асуудалд ордог. Тиймээс манай эмнэлэг эрүүл амьдралын хэв
                  маяг, хооллолт, дотоод эрхтний асуудал цусны бохирдол зэргийг
                  эмчилж болон дотроос хавсарсан эмчилгээг зэрэг хийдгээрээ
                  онцлогтой
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
